/**
 * CkSelectTime Component
 * 
 * `CkSelectTime` is a custom Select component built using Ant Design's Select and Form.Item components. 
 * It is mainly used to provide a customizable select field input mechanism with various styling options for time data.
 *
 * @component
 * @example
 * // Here is an example for CkSelectTime with the variant "default"
 * <CkSelectTime variant="default" />
 *
 * @extends {FormItemProps}
 *
 * @property {"default" | "rounded"} variant - Optional: Specifies the style of the select field. Alternative is "rounded". Default is "default"
 * @property {SelectProps} inputProps - Optional: Additional properties to pass to the internal Ant Design Select component.
 * @property {string} className - Optional: Additional CSS classes to apply
 * @property {string} popupClassName - Optional: Controls the CSS classes to apply to the popup of the select
 * @property {ForwardedRef<RefSelectProps>} forwardRef - Optional: A forwarded ref of generic type RefSelectProps to be applied to the select input
 * @property {boolean} allowClear - Optional: If true, enables the value in the select field to be cleared
 * @property {"lowercase" | "uppercase" | "capitalize"} tansformOptions - Optional: Transforms options to the specified case style
 */
import React, { FC, ForwardedRef } from "react";
import { Select, Form, SelectProps, RefSelectProps } from "antd";
import { FormItemProps } from "antd/lib/form";
import { CkIcon } from "../../atoms";
import "./styles.css";

export interface CkSelectTimeProps extends FormItemProps {
  /**
   * Optional: Specifies the style of the select field. Alternative is "rounded". Default is "default"
   */
  variant?: "default" | "rounded";
  /**
   * Optional: Additional properties to pass to the internal Ant Design Select component.
   */
  inputProps?: SelectProps;
  /**
   * Optional: Additional CSS classes to apply
   */
  className?: string;
  /**
   * Optional: Controls the CSS classes to apply to the popup of the select
   */
  popupClassName?: string;
  /**
   * Optional: A forwarded ref of generic type RefSelectProps to be applied to the select input
   */
  forwardRef?: ForwardedRef<RefSelectProps>;
  /**
   * Optional: If true, enables the value in the select field to be cleared
   */
  allowClear?: boolean;
  /**
   *  Optional: Transforms options to the specified case style
   */
  tansformOptions?: "lowercase" | "uppercase" | "capitalize";
}

/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * inputProps extends AntD Select, see full docs on https://4x.ant.design/components/select/
 */
const CkSelectTime: FC<CkSelectTimeProps> = ({ variant = "default", forwardRef, allowClear, className, popupClassName, inputProps, tansformOptions, ...props}) => (
  <Form.Item 
    className={["ck-select-time", variant, ...(className ? [className] : [])].join(" ")}
    {...props}
  >
    <Select
      getPopupContainer={(e) => e.parentNode}
      popupClassName={["ck-select-time-popover", variant, ...(popupClassName ? [popupClassName] : []), ...(tansformOptions ? [tansformOptions] : [])].join(" ")}
      suffixIcon={<CkIcon name="clock" />}
      {...(allowClear ? { 
            allowClear: true,
            clearIcon: <CkIcon name="close" />
          } : {})}
      {...(forwardRef ? {ref:forwardRef} : {})} 
      {...(inputProps || {})} 
    />
  </Form.Item>
)

export default CkSelectTime;
