/**
 * CkRate Component
 *
 * `CkRate` is a custom rating component built upon Ant Design's Rate. It provides a rating system interface to display and collect star ratings.
 *
 * @component
 * @example
 * // Here is an example of CkRate with a label, custom class name, set to show the star count, and initial value
 * <CkRate label="Rate this" className="custom-rate" showCount={true} value={3} />
 *
 * @extends {RateProps}
 *
 * @property {string} label - Optional: A label for the rating field
 * @property {string} className - Optional: Additional CSS classes to apply
 * @property {boolean} showCount - Optional: If true, shows the count of stars chosen out of the maximum number of stars
 * @property {number} value - The current rating value
 */
import React, { FC } from "react";
import { Rate, RateProps } from "antd";
import "./styles.css";

export interface CkRateProps extends RateProps {
  /**
   * Optional: A label for the rating field
   */
  label?: string;
  /**
   * Optional: Additional CSS classes to apply
   */
  className?: string;
  /**
   * Optional: If true, shows the count of stars chosen out of the maximum number of stars
   */
  showCount?: boolean;
  /**
   * The current rating value
   */
  value: number;
}

/**
 * Extends AntD Rate props
 * See full doc on https://4x.ant.design/components/rate/
 */
const CkRate: FC<CkRateProps> = ({ label, className, showCount, value, count, ...props}) => {

  return (
    <div className={["ck-rate", ...(className ? [className] : [])].join(" ")}>
      {label && <p className="rate-label">{label}</p>}
      <div className="rate-container">
        <Rate
          count={5}
          value={value}
          {...(props?.allowHalf ? {allowHalf: props?.allowHalf} : {allowHalf: true})}
          {...props}
        />
        {showCount &&
          <p className="rate-count">
            {value} de 5
          </p>}
      </div>
    </div>
  )
}

export default CkRate;
